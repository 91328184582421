import React from "react";

const ExpressionBuilderComponent = ({name, propvalue, compvalue, onPropChange, onCompChange}) => {
    const propkey = name +"Property";
    const compkey = name + "Comparator";

   /* var wordArray
    if (props.phrase !== ""){
        wordArray = props.phrase.split('.');
    }
    else {wordArray = ["", ""];}

    const updatedExpressions = [...expressionValues];
    updatedExpressions[propkey] = wordArray[0];
    updatedExpressions[compkey] = wordArray[1];*/
    //setExpressionValues({...expressionValues, [propkey]: wordArray[0], [compkey]: wordArray[1]});
    return (
        <div>
            <br />
            Account Property:
            <select key={propkey} name={propkey} value={propvalue} onChange={onPropChange}>
                <option value=" ">Select a Property</option>
                <option value="geography">Geo</option>
                <option value="billingCountry">Billing Country</option>
                <option value="state">Billing State</option>
                <option value="city">Billing City</option>
                <option value="empCount">FTE (Employee Count)</option>
                <option value="industry">Industry</option>
                <option value="subIndustry">Sub-Industry</option>
                <option value="segment">Segment</option>
                <option value="billingPostalCode">Postal Code</option>
            </select>
            Comparator:
            <select key={compkey} name={compkey} value={compvalue} onChange={onCompChange}>
                <option value="">Equals</option>
                <option value="startswith">Starts With</option>
                <option value="endswith">Ends With</option>
                <option value="between">Between</option>
            </select>
        </div>
    );
}

export default ExpressionBuilderComponent;