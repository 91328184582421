import ReactFlow, { MiniMap, Background, useNodesState, useEdgesState, addEdge  } from 'reactflow';
import 'reactflow/dist/style.css';
import React, { useEffect, useState, useCallback, useContext } from "react";
import dagre from 'dagre';
import './css/column.css';
import EditableNode from './components/EditableNode.js';
import Header from './components/Header';
import LogoutBtn from './components/LogoutBtn';
import TestMode from './components/TestMode';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { AuthContext } from './components/AuthContext';
import endpoints from "./endpoints.json";


const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};


function Flow() {

 /* const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);*/
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);
  const [defaultNode, defaultNodeUpdater] = useState({data: {}});
  const [seed, setSeed] = useState(1);
  const authContext = useContext(AuthContext);
  const token = authContext.token;

  const onNodeClick = (event, node) => {
    console.log("NODE CLICKED " + node.id);
    defaultNodeUpdater(node);
    setSeed(Math.random());
  }

  useEffect(() => {
    defaultNodeUpdater(defaultNode);
  }, [defaultNode]);

  const getVerts = useCallback(() => {
    console.log("TOKEN VALUE " + token);
    fetch(endpoints.vertices, {
      headers: {
        Authorization : `Bearer ${token}`
      }
    })
    .then(async vertResp => {
      const jsonData = await vertResp.json();
      console.log(jsonData);
      var verts = [];
        for (var i = 0; i < jsonData.length; i++){
          var nodeStyle = {};
          if (jsonData[i].count == 0 ){
            nodeStyle =  {
              background: 'pink',
              color: 'black',
            }
          }
            var node = {
                id: ''+jsonData[i].id,
                data: {label: jsonData[i].title, exp: jsonData[i].expression},
                position: {x: jsonData[i].id, y: jsonData[i].id},
                sourcePosition: 'bottom',
                targetPosition: 'top',
                style: nodeStyle,
            }
            verts.push(node);
        }
      setNodes(verts);
    })
    .catch(err => {
      console.log("Error building vertices: " + err);
    });
  }, [token, setNodes]);

  const getEdges = useCallback(() => {
    fetch(endpoints.edges, {
      headers: {
        'Authorization' : 'Bearer ' + token
      }
    })
    .then(async edgeResp => {
      const jsonData = await edgeResp.json();
      console.log(jsonData);
      var edgesArr = [];
      for (var i = 0; i < jsonData.length; i++){
        var edge = {
          id: ''+jsonData[i].id,
          source: ''+jsonData[i].parent,
          target: ''+jsonData[i].child,
          label: jsonData[i].operation + jsonData[i].rule,
        }
        edgesArr.push(edge);
      }
      setEdges(edgesArr);
    })
    .catch(err => {
      console.log("Error building edges");
    });
  }, [token, setEdges]);

  useEffect(() => {
    if (token != null){
      getEdges();
      getVerts();
    }
  }, [token, getEdges, getVerts])

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        direction
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges, setEdges, setNodes]
  );

  useEffect(() => {
    onLayout("LR");
  }, [nodes, edges, onLayout])

  const refresh = ()=>{
    // it re-renders the component
    getVerts();
    getEdges();
  }

  return (
    <div className='topbox'>
      <div className='left-column'>
        <Router> 
          <div class="top-row">
            <h3>Enterprise Territory Management Planner</h3>
            <Header />
          </div>
          <div class="middle-row">
            <Routes>
              <Route path="/" element={<EditableNode key={seed} node={defaultNode} ></EditableNode>} />
              <Route path="/testMode" element={<TestMode />} />
            </Routes>  
          </div>
          <div class="bottom-row">
            <LogoutBtn />
            <div class="right-aligned-buttons">
              Layout: 
              <button onClick={() => onLayout('TB')}>vertical</button>
              <button onClick={() => onLayout('LR')}>horizontal</button>
              <button onClick={refresh} title="Refresh">
                <img src="/icons/rotate-right-solid.svg" alt="Refresh" width="24" height="24" />
              </button>
            </div>
          </div>
        </Router>
      </div>
      <div className='right-column'>
        <ReactFlow nodes={nodes} edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={onNodeClick}
          fitView
          attributionPosition="bottom-left">
          <Background color="#000000" gap={16}/>
          <MiniMap pannable zoomable />
        </ReactFlow>
      </div>
    </div>
  );
}

export default Flow;

