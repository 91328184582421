import React, {useContext, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import { AuthContext } from './components/AuthContext';

const Home = () => {
  const {
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      const accessToken = await getAccessTokenSilently();
      console.log("ACCESS TOKEN!: " + accessToken);
      authContext.setToken(accessToken);
    }

    fetchData();
  }, [isAuthenticated, authContext, getAccessTokenSilently]);

  if (error) {
    return <div class="login-container">Oops... {error.message}</div>;
  }

  return (
    <div>

    {!isAuthenticated && (
      <div class="login-container">
        <h2>Please log in to use the ETM Rule Editor application!</h2>
        <button class="login-button" onClick={() => loginWithRedirect()}>Login</button>
      </div>
    )}


    {isAuthenticated && (
      <App/>
    )}

    </div>
  )
};

export default Home;
