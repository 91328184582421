import {React, useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import endpoints from "../endpoints.json";


function TestMode(){
    const [geo, setGeo] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [employees, setEmployees] = useState('');
    const [industry, setIndustry] = useState('');
    const [subindustry, setSubindustry] = useState('');
    const [segment, setSegment] = useState('');
    const [postalcode, setPostalcode] = useState('');
    const [region, setRegion] = useState('');
    const [accountName, setAccountName] = useState('');
    const [publicSector, setPublicSector] = useState('');
    const [website, setWebsite] = useState('');
    const [testResult, setTestResult] = useState(null);
    const authContext = useContext(AuthContext);
    const token = authContext.token;

    const runTest = (event) => {
        setTestResult("Processing...");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var raw = JSON.stringify({"mode": "test", 
            "account": {
                "geo": geo, 
                "BillingCountry":country, 
                "BillingState": state, 
                "BillingCity": city, 
                "Employees_Master__c": employees, 
                "Primary_Industry__c": industry, 
                "Sub_Industry__c": subindustry, 
                "segment": segment, 
                "BillingPostalCode": postalcode,
                "website": website,
                "Sales_Region__c" : region,
                "name" : accountName,
                "Public_Sector__c" : publicSector
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(endpoints.testMode, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("TestMode Response: ", data); 
            const prefix = "Calculated Territory: ";
            let msg = data.message
            if (msg.startsWith(prefix)) {
                msg = msg.slice(prefix.length).trim();
            }
            setTestResult(msg);
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div>
            <p>
                Evaluate the ETM rules using test data and the form properties below.
            </p>

            <div class="form-container">
                <h4>Account Properties:</h4>
                <div class="form-group">
                    <label for="geo">Geo:</label>
                    <input id="geo" type="text" value={geo} onChange={event => setGeo(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="country">Country:</label>
                    <input id="country" type="text" value={country} onChange={event => setCountry(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="state">State:</label>
                    <input id="state" type="text" value={state} onChange={event => setState(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="city">City:</label>
                    <input id="city" type="text" value={city} onChange={event => setCity(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="employees">Employees Master:</label>
                    <input id="employees" type="text" value={employees} onChange={event => setEmployees(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="industry">Industry:</label>
                    <input id="industry" type="text" value={industry} onChange={event => setIndustry(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="subind">SubIndustry:</label>
                    <input id="subind" type="text" value={subindustry} onChange={event => setSubindustry(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="seg">Segment:</label>
                    <input id="seg" type="text" value={segment} onChange={event => setSegment(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="zip">Postal Code:</label>
                    <input id="zip" type="text" value={postalcode} onChange={event => setPostalcode(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="accountName">Account Name:</label>
                    <input id="accountName" type="text" value={accountName} onChange={event => setAccountName(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="region">Region:</label>
                    <input id="region" type="text" value={region} onChange={event => setRegion(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="publicSector">Public Sector:</label>
                    <input id="publicSector" type="text" value={publicSector} onChange={event => setPublicSector(event.target.value)}></input>
                </div>
                <div class="form-group">
                    <label for="website">Website:</label>
                    <input id="website" type="text" value={website} onChange={event => setWebsite(event.target.value)}></input>
                </div>
                <div class="form-button-container">
                    <button onClick={() => runTest()}>Run Test</button>
                </div>
            </div>

            <div class="form-container">
                <h4>Test Results:</h4>
                <div class="form-group">
                    <label>Calculated Territory:</label>
                    <input type="text" value={testResult}></input>
                </div>
            </div>
        </div>
    );
}

export default TestMode;
