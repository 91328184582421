import React, {useState, useEffect} from 'react';
import ExpressionBuilderComponent from "./ExpressionBuilder"

const EditableNode = ({node}) => {
    const [title, setTitle] = useState("");
    const [expression, setExpression] = useState("");
    const [newPropertiesCount, setNewPropertiesCount] = useState(0);
    const [expressionValues, setExpressionValues] = useState({}); 
    

    const updateNode = async (node) => {
        console.log("EDITABLENODE : " + node.id);

        if (title == undefined && expression == undefined){
            console.log("Not updating because nothing changed.");
            return;
        }else{
            if (title == undefined){
                setTitle(node.data.label);
            }
            else if (expression == undefined){
                console.log("Setting expression ");
                setExpression(node.data.exp);
            }
            try {
                const body = {title: title, expression: expression};
                console.log("body : " + JSON.stringify(body));
                const response = await fetch(`http://localhost:3080/vertices/${node.id}`,
                    {method: "PUT",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(body)}
                );
                window.location = "/";
            } catch (error) {
                console.error(error.message);
            }
        }
    }

    useEffect(() => {
        if (node.data.exp != undefined){
            setExpression(node.data.exp);
        }

      }, [node.data.exp]);

    const addToExpression = () =>{
        //loop through expression array to rebuild string
        /*[ebc0: 
            options: [
                'geo',
                '',
            ]},
           {id: 'exp2', 
            options:[
                'empCount',
                'between'
            ]}
        ]*/
        var exp = "";
        Object.entries(expressionValues).map(([key, options]) => {
            //let entry = expressionValues[i]
            let property = expressionValues[key].options.property
            let comparator = expressionValues[key].options.comparator
            if (comparator == undefined) exp += property + "|";
            else
                exp += property + "." + comparator + "|"
        });
        exp = exp.slice(0, -1); 
        console.log(exp);
        setExpression(exp);
    }

    const addProperty = () =>{
        console.log("Properties Count: " + newPropertiesCount);
        setNewPropertiesCount(newPropertiesCount + 1);
    }

    useEffect(() => {
        var tempExpressions = {};
        var exp = node.data.exp || "";
        const expressionArray = exp.split('|');
        for (let i = 0; i < expressionArray.length; i++){
            var phrase = expressionArray[i];
            var id = "ebc" + i;
            var words = phrase.split('.');
            tempExpressions[id] = {
                options: {property: words[0],
                comparator: words[1]}};
        }
        console.log("TempExpressions: ", tempExpressions);
        setExpressionValues(tempExpressions);
     }, [node]);

   /* useEffect(() => {
        console.log("EXPRESSION: " + expression);
        if(expression && expression !== null && expression !== undefined){
            setExistingExpressions(expressionPairs());
        }
    }, [expressionValues]);*/

    function handleSelectChange (id, value, pos){
        let entry = expressionValues[id];
        //if it doesn't exist, create a new one
        if (pos == "comparator")
            entry.options.comparator = value;
        else   entry.options.property = value;
        console.log(entry);
        setExpressionValues({...expressionValues, [id]: entry})
    }

    return (
        <div className="updateNode">
            <label>Label:</label>
            <input defaultValue={node.data.label} 
                    style={{width:'100%'}}
                    onChange={e => setTitle(e.target.value)}
            />
            <br />
            <br />
            <label>Expression Builder:</label>
            {Object.entries(expressionValues).map(([key, options]) => (
            //let entry = expressionValues[i]
            //let property = expressionValues[key].options.property
            //let comparator = expressionValues[key].options.comparator
            <ExpressionBuilderComponent key={key} name={key} 
                propvalue={expressionValues[key]?.options?.property || ''} 
                compvalue={expressionValues[key]?.options?.comparator || ''} 
                onPropChange={(e) => handleSelectChange(key, e.target.value, "property")}
                onCompChange={(e) => handleSelectChange(key, e.target.value, "comparator")}/>
            ))
            }
            {Array.from({length: newPropertiesCount}).map((_, i) => (
                <ExpressionBuilderComponent phrase="" key={"new"+i} 
                onPropChange={(e) => handleSelectChange("new"+i, e.target.value, "property")}
                onCompChange={(e) => handleSelectChange("new"+i, e.target.value, "comparator")}/>
            ))}
            <br />
            <button onClick={addProperty}>Add Property</button>
            <button onClick={addToExpression}>Add To Expression</button>
            <br />
            <label>Expression:</label>
            <input defaultValue={expression} 
                    style={{width:'100%'}}
                    onChange={e => setExpression(e.target.value)} disabled
            />
            <br/>
            <button onClick={() => updateNode(node)}>Save</button>
        </div>
        )
    };

export default EditableNode;