import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';


function Header() {
  return (
    <Nav variant="pills">
      <Nav.Item eventKey="/">
        <LinkContainer to="/">
          <Nav.Link eventKey="/">Rules Editor</Nav.Link>
        </LinkContainer>
      </Nav.Item>

      <Nav.Item eventKey="/testMode">
      <LinkContainer to="/testMode">
        <Nav.Link eventKey="/testMode">Test Mode</Nav.Link>
      </LinkContainer>
      </Nav.Item>
    </Nav>
  );
}

export default Header;