import React, { useState } from 'react';

export const AuthContext = React.createContext({
    token: null,
    setToken: () => {}
});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    return (
        <AuthContext.Provider value = {{token, setToken}}>
            {children}
        </AuthContext.Provider>
    );
};